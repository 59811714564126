@import '../../scss/shared.scss';

:local {

    .secondaryButton {
        color: #881155;
        background: none;
        border: none;
    }
    .cardLayout {
        display: flex;
        justify-content: space-between;
        height: 155px;
        width: 100%;
        position: relative;
        background: #FFFFFF;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
        border-radius: 10px;

        padding: 16px;
        margin: 16px 0;

        z-index: 2;

        @media(max-width: $screen-sm-max) {
            height: auto;
            margin: 0 0 32px auto;
            flex-direction: column;
            padding: 0 0 10px;
        }

        @media(max-width: $screen-srp-mobile-max) {
            margin: 0 auto 32px;
        }


    }

    @media(max-width: $screen-srp-mobile-max) {
        .focused {
            transform: translateZ(0px); // Hack to fix stacking issues
            .cardLayout {
                height: auto;
            }
            .cardContent {
                flex-direction: row;
                flex-wrap: wrap;
                height: auto;
                padding: 16px;

                .imageSection {
                    width: 124px;
                    min-width: auto;
                    height: 90px;
                    img {
                        height: 90px;
                    }
                    span {
                        display: none;
                    }
                }

                .roomsSection {
                    display: none;
                }

                .infoSection {
                    display: flex;
                    width: 100px;
                    padding: 0 0 0 16px;
                }

                .infoLocation {
                    padding: 0;
                    order: 1;
                }

                .infoTitle {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }

                .infoRating span{
                    display: inline;
                }

                .distance {
                    display: none;
                }

                .buttonsSection {
                    flex-grow: 1;
                    justify-content: space-between;
                    margin-top: 15px;
                    width: 100%;
                    button {
                        margin: 0;
                    }
                }

                .quickViewButton {
                    display: none;
                }
            }

            .sliderContainerWrapper,
            .sliderImageWrapper,
            .slideContainerWrapper{
                height: 90px;
            }
        }
    }

    .cardLayoutQM{
        @extend .cardLayout;
        height: auto;
    }

    .cardContent {
        display: flex;
        @media(max-width: $screen-sm-max) {
            flex-wrap: wrap;
        }
    }

    .cardContentQM {
        flex-direction: column;
        width: 100%;

        .sliderContainerWrapper,
        .sliderImageWrapper,
        .slideContainerWrapper{
            height: 240px;
        }
    }

    .imageSection {
        min-width: 160px;
        height: 123px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #d1d3d8 url("../../assets/images/rings.svg") no-repeat center;

        img {
            width: 160px;
            height: 123px;
            border-radius: 4px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            object-fit: cover;

            &:before {
                height: 123px;
                display: flex;
                z-index: -10;
            }
        }

        span {
            position: absolute;
            width: 76px;
            height: 20px;
            top: 18px;
            left: 18px;
            padding-top: 3px;
            z-index: 2;
            background: rgba(255, 255, 255, 0.9);
            border-radius: 10px;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #881155;
        }

        @media(max-width: $screen-sm-max) {
            height: 170px;
            width: 100%;

            img {
                width: 100%;
                height: 170px;
            }
        }
    }

    .imageSectionQM {
        @extend .imageSection;
        height: 240px;

        img {
            width: 100%;
            height: 100%;
        }

        @media(max-width: $screen-sm-max) {
            height: 170px;
            width: 100%;
            overflow: hidden;

            img {
                width: 100%;
                height: 170px;
            }
        }
    }

    .infoSection {
        padding: 0 16px 0 24px;
        flex-direction: column;
        flex-grow: 1;
        svg {
            overflow: visible !important;
        }
        @media(max-width: $screen-sm-max) {
            padding-top: 18px;
        }
    }

    .infoSectionQM {
        @extend .infoSection;
        padding: 14px 0 0;
        svg {
            overflow: visible !important;
        }
        .infoLocation {
            display: none;
        }
        @media(max-width: $screen-sm-max) {
            padding: 18px 16px 0;
            width: auto;
            .infoLocation {
                display: block;
            }
        }

    }

    .infoTitle {
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        line-height: 28px;
        color: #881155;
        align-self: flex-start;
        word-break: break-all;
    }

    .infoLocation {
        display: flex;
        padding-top: 4px;

        svg {
            padding-right: 2px;
            position: relative;
            top: 3px;
        }
    }

    .infoText {
        color: #69717A;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        font-size: 12px;
        line-height: 16px;
    }

    .infoRating {
        display: flex;
        padding-top: 4px;

        span {
            @extend .simpleText;
            order: 0;
            align-self: flex-start;
            flex-grow: 0;
            margin: 4px 0;
            padding-right: 3px;
        }

        div {
            display: flex;
        }

        svg {
            fill: #69717A;
            margin: 4px 3px 4px 0;
        }

        @media(max-width: $screen-sm-max) {
            padding: 0;
        }
    }

    .buttonsSection {
        border-left: 1px solid #D9DCDE;
        display: flex;
        flex-direction: column;

        @media(max-width: $screen-sm-max) {
            flex-direction: row-reverse;
            border-left: 0;
            align-items: center;
            justify-content: center;
            border-top: 1px solid #D9DCDE;
        }
    }

    .buttonsSectionQM {
        @extend .buttonsSection;
        margin-left: 16px;
        @media(max-width: $screen-sm-max) {
            margin: 0;
        }
    }

    .selectButton {

        button {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 32px;
            width: 140px;
            margin: 0 8px;
            background: #881155;
            border: 1px solid #881155;
            box-sizing: border-box;
            border-radius: 4px;
            color: white;
            font-size: 14px;

            @media(max-width: $screen-sm-max) {
                width: 130px;
            }
        }

        span {
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #FFFFFF;
        }

        @media(max-width: $screen-sm-max) {
            padding-top: 10px;
        }

    }

    .selectButtonSelected button {
        color: white;
        background: #00CA9D;
        border: 1px solid #00CA9D;

        svg {
            margin-right: 4px;
        }
    }

    .quickViewButton {
        padding-top: 10px;

        button {
            height: 32px;
            width: 140px;
            margin: 0 8px;
            background: #FFFFFF;
            border: none;
            @media(max-width: $screen-sm-max) {
                width: 124px;
            }
        }

        span {
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #881155;
        }
    }

    .roomsSection {
        @extend .infoText;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 100%;
        grid-template-rows: repeat(5, auto);
        line-height: 18px;

        @media(max-width: $screen-sm-max) {
            padding: 10px 0;
            grid-auto-columns: 50%;
            grid-auto-flow: row;
            grid-template-columns: repeat(2, auto);

            .firstFullRow {
                margin-top: 8px;
            }
            .fullRow {
                grid-column: 1 / -1;
            }
        }
    }

    .roomsText {
        @extend .infoText;
        padding-top: 3px;
    }

    .contentSection {
        display: flex;
        width: 100%;
    }

    .sliderContainerWrapper{
        width: 100%;
        height: 240px;
    }

    .sliderImageWrapper{
        overflow: hidden;
        position: relative;
        height: 240px;
        width: 100%;
    }

    .slideContainerWrapper{
        height: 240px;

        :global {
            .lazyload-wrapper {
                height: 100%;
                .SlideImage {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .simpleText {
        font-size: 12px;
        line-height: 16px;
        color: #1A2026;
        @media(max-width: $screen-sm-max) {
            display: none;
        }
    }

    .infoDescription {
        @extend .infoText;
        padding-top: 8px;
    }

    .infoAmenities {
        @extend .simpleText;
        padding-top: 16px;
        @media(max-width: $screen-sm-max) {
            display: block;
            margin-bottom: 16px;
        }
    }

    .infoAmenitiesContainer {
        padding-top: 8px;
        display: grid;
        grid-template-areas: "a a";
        grid-auto-columns: 200px;

        @media(max-width: $screen-sm-max) {
            grid-auto-columns: 50%;
        }

        span {
            @extend .infoText
        }
    }
}
