@import '../../scss/shared.scss';


:local .className {
  width: 100%;
}

.slick-slide > div {
  height: 250px;
  @include nx-medium-up {
    height: 450px;
  }
}

:local .placeholder {
  margin-bottom: 43px;
  height: 250px;
  background: #D1D3D9;
  text-align: center;

  img {
    height: 100%;
  }
}

@include nx-medium-up {
  :local .placeholder {
    height: 450px;
  }
}

:local .imageWrapper {
  position: relative;
  background-color: #bbbbbb;
  height: 250px;
}

:local .reportCreditLink {
  position: absolute;
  top: 12px;
  right: 0;
  cursor: pointer;
  padding: 0 1px;
  color: inherit;
}

:local .reportCreditLinkText {
  margin-right: 5px;
}

:local .imageWrapperInner {
  position: relative;
  width: 100%;
  height: 100%;
}

:local .photoCredit {
  position: relative;
  color: #666;
  padding: 12px 0;
  height: 44px;
  background-color: #f6f7f8;
  @include nx-small-only {
    background-color: #fff;
    padding: 12px;
  }
}

:local .photoCreditText {
  max-width: 90%;
}

:local .photoCreditLink {
  position: absolute;
  top: 12px;
  right: 0;
  cursor: pointer;
  padding: 0px 1px;
  color: inherit;

  @include nx-small-only {
    top: 12px;
    right: 10px;
  }
}

:local .photoCreditLinkText {
  margin-right: 5px;
  @include nx-small-only {
    display: none;
  }
}

:local .controls {
  @include clearfix;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 14px;
  z-index: 2;
}

:local .counter {
  position: absolute;
  right: 16px;
  bottom: 13px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  text-shadow: 0px 0px 1px #3c3c3c;
}

:local .videoRendered {
  display: none;
}

:local .navigationWrapper {
  width: 200px;
  margin: 0 auto;
  text-align: center;
}

:local .ctaSlideContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

:local .ctaWrapper {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  padding: 170px 0 150px 0;
  text-align: center;
}

:local .ctaText {
  color: #fff;
  font-size: 31px;
}

:local .ctaButton {
  margin-top: 30px;
}

:local .dotNavigation {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 8px;
  margin-right: 4px;
  vertical-align: middle;
  cursor: pointer;
  box-shadow: 0px 0px 1px #3c3c3c;
}

:local .dotNavigationActive {
  vertical-align: top;
  margin-top: 4px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
}

:local .arrowLeft,
:local .arrowRight {
  display: flex;
  font-size: 40px;
  color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 2;
  width: 1em;
  height: 1em;
}

:local .arrowLeftVideoPlaying,
:local .arrowRightVideoPlaying {
  display: none !important;
}

:local .arrowLeft {
  top: calc(50% - 16px);
  left: 21px;
  justify-content: flex-start;
}

:local .arrowRight {
  top: calc(50% - 16px);
  right: 21px;
  justify-content: flex-end;
}

:local .videoContainer {
  position: absolute;
  z-index: 1;
  width: 375px;
  height: 250px;

  .SpinnerOverlay {
    width: 100%;
    height: 100%;

    &--content {
      width: 100%;
      height: 100%;
    }
  }
}

:local .playerWrapper {
  height: 100%;
}

:local .videoPlayButton {
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  display: inline-block;
  z-index: 2;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;

  width: 70px;
  height: 70px;
  border: solid 4px #fff;
  border-radius: 100%;

  &:after {
    content: ' ';
    width: 30px;
    height: 30px;
    display: block;
    margin: 3px 10px 5px 15px;

    width: 0;
    height: 0;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;

    border-left: 18px solid #fff;
  }
}

@include nx-medium-up {
  :local .className {
    .slick-slide > div {
      height: 450px;
    }
  }

  :local .imageWrapper {
    height: 450px;
    margin: 0;
  }
  :local .videoContainer {
    width: 900px;
    height: 450px;
  }

  :local .arrowLeft,
  :local .arrowRight {
    display: flex;
    text-shadow: 0px 0px 1px #3c3c3c;
  }

  :local .image {
    > img {
      max-height: 450px;
    }
  }
}

.forcedWeb {
  display: block;
}

:local .allowMobileView {
  @include nx-small-only {
    :local .triangle {
      top: 0;
      right: 0;
      width: 0;
      height: 0;
    }

    :local .triangleRight {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 6px solid rgba(255, 255, 255, 0.75);
      display: inline-block;
      vertical-align: middle;
    }

    :local .triangleLeft {
      margin-right: 4px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 6px solid rgba(255, 255, 255, 0.75);
      display: inline-block;
      vertical-align: middle;
    }

    :local .ctaWrapper {
      padding: 40px 10px 10px 10px;
    }

    :local .ctaText {
      font-size: 25px;
    }

    :local .arrowLeft,
    :local .arrowRight {
      display: none;

      &__video-stopped {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
      }
    }
  }
}

