@import '../../scss/shared.scss';

:local {
    .container {
        display: none;
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;

        @media all and (max-width: 768px) {
            padding: 18px 0 0 0;
        }
    }

    .cartOpen {
        display: block;
        @media all and (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
    }

    .header {
        position: relative;
        padding: 0 20px;

        button {
            cursor: pointer;
            float: right;
            position: absolute;
            top: 0;
            right: 20px;
            padding: 10px 11px;
            width: auto;
            min-width: auto;
            color: #24272b;
            border-color: rgba(105, 113, 122, 0.5);
        }
        @media all and (min-width: 769px) {
            padding: 0;
            button {
                display: none;
            }
        }
    }

    .title {
        font-size: 21px;
        margin-bottom: 15px;
        padding-top: 4px;

        @media all and (max-width: 768px) {
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
            text-align: left;
            color: #575a60;
        }
    }

    .subtitle {
        font-size: 16px;
        color: #69717a;
        margin-bottom: 15px;
        @media all and (max-width: 768px) {
            margin-top: 24px;
        }
    }

    .closeButton {
        cursor: pointer;
        float: right;
        position: absolute;
        top: 0;
        right: 0;

        @media all and (max-width: 768px) {
            display: none;
        }
    }

    .recommendationsTitle {
        font-size: 14px;
        text-align: center;
        margin: 15px 0;
    }

    .addMoreCTAContainer {
        box-sizing: border-box;
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border: 1px dashed #d9dcde;
        border-radius: 4px;
        font-size: 14px;
        margin-bottom: 6px;
    }
    .addMoreCTAText {
        color: $color-brand-primary;
        cursor: pointer;
    }

    .submitButtonContainer {
        button {
            width: 100%;
            margin-bottom: 10px;
        }
        @media all and (max-width: 787px) {
            box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.239216);
            width: 100%;
            padding: 8px 22px 16px;
        }
    }

    .submitCTASubtitle {
        font-size: 16px;
        color: #69717a;
        text-align: center;
    }

    .listWrapper {
        max-height: 370px;
        overflow-y: auto;
        overflow-x: hidden;

        @media all and (max-width: 768px) {
            padding: 0 20px;
            max-height: none;
            flex: 1;
        }
    }
}
