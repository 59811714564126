@keyframes jingle {
    0% {
        transform: rotateZ(0);
    }
    3% {
        transform: rotateZ(-15deg);
    }
    9% {
        transform: rotateZ(15deg);
    }
    15% {
        transform: rotateZ(-7deg);
    }
    21% {
        transform: rotateZ(7deg);
    }
    27% {
        transform: rotateZ(0);
    }
}

:local .ringsSpinner {
    width: 100px;
    height: 100px;
}

:local .ringsSpinner__animated {
    animation: jingle 2.0s linear 0.7s infinite;
}
