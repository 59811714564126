$screen-srp-mobile-max: 750px;  /* TODO: We shouldn't have these application/page-specific
                                  breakpoints in components lib. It's better if we design the 
                                  components to allow the calling application to specify this */

:local {
    .className {
        width: 100%;
        text-align: left;
    }


    .headline {
        color: #999;
        padding: 10px 5px 0 10px;
        font-size: 16px;
        margin: 0 0 10px 0;
        text-transform: uppercase;
        display: block;
    }

    .link {
        display: block;
        text-decoration: none;
        padding: 5px 10px;
        color: inherit;

        &:hover {
            background-color: #2c94ff;
            color: #fff;
        }
        &__brandColorBackground:hover {
            background-color:#881155 !important;
            color: #fff;
        }      

        > span {
            font-weight: 600;
        }
    }

    .linkSelected {
        background-color: #2c94ff;
        color: #fff;
    }

    .listWrapper {
        border-bottom: solid 1px #f0f0f0;
        padding: 10px 0;

        &:last-child {
            border: none;
        }
    }

    // TODO: this styling should be configurable by the app that's using this component
    @media(max-width: $screen-srp-mobile-max) {
        .headline {
            padding: 10px 16px;
            font-size: 15px;
            font-weight: 600;
            margin: 0;
            background: rgba(196, 196, 196, 0.15);
            color: #24272B;
            opacity: 0.5;
        }
        .link {
            padding: 8px 16px;
            color: #24272B;
            font-size: 16px;
            line-height: 24px;
        }
        .listWrapper {
            border: none;
            margin-bottom: 16px;
        }
    }
}
