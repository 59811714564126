@import '../../scss/shared.scss';

:local {
    .container {
        position: relative;

        &.isFixed {
            position: fixed;
            bottom: 8px;
            right: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #881155;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            z-index: 10;

            .body {
                padding-top: 4px;
                svg {
                    path {
                        fill: white;
                    }
                }

                .label {
                    top: -5px;
                    background: white;
                    color: #881155;
                }
            }
        }
    }

    .body {
        position: relative;
        svg {
            width: 24px !important;
            height: 24px !important;
        }
        path {
            fill: rgb(105, 113, 122);
        }
        .label {
            width: 21px;
            height: 20px;
            text-align: center;
            line-height: 22px;
            border-radius: 50%;
            overflow: hidden;
            background: #881155;
            position: absolute;
            top: -10px;
            right: -8px;
            font-size: 14px;
            color: white;

            &:empty {
                display: none;
            }
        }
    }

    .shoppingCartFlyout {
        display: flex;
        align-items: center;

        @media (max-width: 749px) {
            & > div:nth-child(2) {
                & > div {
                    transform: translate3d(-7px, 54px, 0);
                    width: calc(100% - 14px);
                    max-width: 425px;
                    left: unset;
                    right: 0;

                    & > div:first-child {
                        left: unset;
                        right: 9px;
                    }
                }
            }
        }
    }
}
