:local {
  .wrapper {
    position: relative;
  }

  .select {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
}
