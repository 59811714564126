@import '../../../scss/shared.scss';

:local {

    .container {
        h2 {
            margin-top: 40px;
            @media (max-width: 767px) {
                margin-top: 32px;
            }
        }
    }
    .wrapper {
        padding: 0 0 16px;
    }

    .imageRow {
        display: flex;
        padding-top: 36px;

        img {
            min-width: 0;
            padding: 0 1px;
            max-height: 179px;
            object-fit: cover;

            @media (max-width: $screen-xs-max) {
                max-height: 30vw;
            }
        }

        @media (max-width: $screen-xs-max) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 4px 1px;
        }
    }

    .imageRowNext {
        @media (max-width: $screen-xs-max) {
            & > div:nth-child(2),
            & > div:nth-child(3) {
                display: none;
            }
        }
    }

    .imageContainer {
        max-width: 33.33%;
        position: relative;

        @media (max-width: $screen-xs-max) {
            max-width: none;

            &:first-child {
                grid-column-start: 1;
                grid-column-end: 3;

                img {
                    max-height: 50vh;
                }
            }
        }
    }

    .otherImagesOverlay {
        position: absolute;
        background: rgba(34, 40, 42, 0.8);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 1.2em;
    }

    .roomsInfo {
        display: flex;
        padding-top: 30px;
        flex-wrap: wrap;

        @media (max-width: $screen-xs-max) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px 0;
        }
    }

    .roomInsight {
        padding-right: 30px;
        min-width: 110px;

        @media (max-width: $screen-xs-max) {
            flex: 0 50%;
            padding-right: 0;
        }
    }
    .emptyRoomBlocsContainer {
        font-family: Proxima Nova, sans-serif;
        font-size: 18px;
        line-height: 125%;

        .copy {
            font-size: 14px;
            line-height: 24px;
        }

        .exploreHotelsBtnWrapper {
            text-align: center;
            margin-top: 15px;
        }
        .exploreHotelsBtn {
            height: 36px !important;
            line-height: 36px !important;
            margin-top: 24px;
            border-radius: 100px;
            color: #881155;
            background-color: transparent;
            border: 1px solid #881155;
            padding: 10px 15px;
        }
    }
    .insightTitle {
        color: #69717a;
        font-size: 13px;
    }

    .insightData {
        font-size: 20px;
        color: #1a2026;
    }

    .ctaButton {
        color: $magenta !important;
        font-size: 12px !important;
        height: 36px !important;
        line-height: 36px !important;
        margin-top: 28px;
    }

    .ctaNextButton {
        border: 1px solid #881155;
        border-radius: 22px;
    }
}

.gallery-modal {
    .thumbnail-button {
        border: 0;
    }

    .gallery-thumbnails--toggle {
        display: none;
    }

    .gallery-control {
        border: 0 !important;
    }
}
