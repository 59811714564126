@import '../../scss/shared.scss';


:local .className {
    position: relative;
    z-index: $z-index-modals; /* TODO: Make this configurable by application */
    background-color: #fff;
    width: 400px;
    padding: 30px;
    margin: 0 auto;
    border-radius: 4px;

    @media (max-width: 768px) {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        margin: 0;
        border-radius: 0;
        overflow-y: auto;
    }
}

:local .closeButton {
    font-size: 24px;
    color: #24272b;
    cursor: pointer;
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    left: auto;
    top: 16px;
    right: 16px;
}
:local .content {
    padding: 0 20px;
}

:local .headline {
    font-size: 23px;
    text-align: center;
    margin: 13px 0;
}

:local .facebookButton {
    button {
        width: 100%;
        border-radius: 2px;
        text-transform: none;
        :global .icon-hb-facebook {
            margin-right: 5px;
        }
    }
}

:local .orWrapper {
    position: relative;
    text-align: center;
    margin: 15px 0;
    &:before {
        content: ' ';
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: #e9e9e9;
        top: 7px;
        left: 0;
    }
}

:local .orText {
    position: relative;
    color: #999;
    padding: 0 15px;
    background-color: #fff;
}


:local .inputWrapper {
    position: relative;
}

:local .input {
    // at least on webkit we will not see yello autofill thing
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset;
    }
    text-align: center;
    height: 48px;
    margin-bottom: 12px;
    width: 100%;
    border-radius: 2px;
    border: solid 1px #e2e2e2;
    font-size: 17px;
    line-height: 26px;
    vertical-align: middle;
    padding: 4px 6px;
    background-color: #fff;
    display: block;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border linear 0.2s;
}

:local .hasError {
    :local .inputWrapper {
        padding-bottom: 20px;
    }

    :local .input {
        margin-bottom: 0;
        border-color: $offred-50;
    }
}

:local .fieldError {
    color: $offred-50;
    position: absolute;
    bottom: 0px;
}

:local .formLevelError {
    color: $offred-50;
    text-align: center;
    margin-bottom: 8px;
}

:local .className {
    :global .nx-button--primary {
        width: 100%;
        height: 50px;
        font-size: 17px;
    }
}
:local .footer {
    margin-bottom: 20px;
    color: #999;
}

:local .footerLinks {
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

:local .passwordResetLink {
    display: inline-block;
    text-align: right;
}
