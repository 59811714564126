:local {
    .title {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #69717a;
        margin: 32px 0 24px;
    }

    .slider {
        margin-top: 28px;
        margin-bottom: 40px;
        position: relative;
    }

    .sliderContainer {
        width: 100%; // for unsupported browsers
        width: calc(100% + 25px);
        position: absolute;
    }

    .sliderItem {
        padding-right: 16px;
    }

    .item {
        &:nth-child(2n - 1) {
            margin-right: 8px;
        }

        &__last {
            display: flex;
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            height: 80px;
            border: 1px solid #d9dcde;
            margin-bottom: 5px;
        }
    }

    .recommendationsList {
        display: flex;
        margin-bottom: 45px;

        @media (max-width: 1000px) {
            flex-wrap: wrap;
        }
    }

    .recommendation {
        background: #ffffff;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08), 0 6px 12px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        width: 248px;
        overflow: hidden;
        margin-right: 24px;
        padding-bottom: 10px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        .image {
            height: 118px;
            width: 100%;
            background: #d1d3d8 url('../../assets/images/rings.svg') no-repeat center;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        h3 {
            margin: 8px 16px 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #24272b;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 4px;
        }

        p {
            margin: 0 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #69717a;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @media (max-width: 1000px) {
            width: calc(50% - 12px);
            margin-bottom: 20px;

            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        @media (max-width: 550px) {
            width: auto;
            margin: 0;
        }
    }
}
