@import '../../scss/shared.scss';


:local .className {
    @include clearfix;
    display: inline-block;
    width: 100%;
}

:local .clearfix {
    @include clearfix;
}

:local .locationsTable {
    width: 100%;
    td {
        text-align: left;
        padding: 5px 14px;
        vertical-align: top;
    }
}

:local .list {
    font-size: 14px;
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;

    > li {
        > a,
        :local .link  {
            display: block;
            padding: 4px 15px;
            &:hover {
                background-color: #eee;
            }
        }
    }
}

:local .textCenter {
    text-align: center;
}