
:local {
  .container {
    position: fixed;
    width: 352px;
    min-height: 96px;
    padding: 16px 38px 16px 16px;
    background: #FFFFFF;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    right: 38px;
    top: 82px;
    z-index: 30;
    overflow: hidden;
    display: flex;

    @media (max-width: 450px) {
      width: calc(100% - 24px);
      top: 68px;
      right: 12px;
    }

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 6px;
      background-color: #881155;
    }

    &.success {
      &:before {
        background-color: #36A336;
      }
    }

    &.danger {
      &:before {
        background-color: #DB2C00;
      }
    }
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
    flex: 1;
  }

  .title {
    margin: 0 0 8px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #24272B;
  }

  .text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #24272B;
    margin: 0;

    a {
      color: #881155;
      cursor: pointer;
      &:hover {
        color: #881155;
      }
    }
  }

  .imageContainer {
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;

    &.noIcon {
      background: #d1d3d8 url("../../assets/images/rings.svg") no-repeat center;
      background-size: 75%;
    }

    svg {
      width: 40px!important;
      height: auto!important;

      path {
        fill: #881155;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .close {
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;

    svg {
      width: 22px!important;
      height: 22px!important;

      path {
        fill: #69717A;
      }
    }
  }
}
