

:local .className {
    min-height: 200px;
    min-width: 960px;
    background-color: rgba(255,255,255,0.94);
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #888;
    box-shadow: 0 1px 4px #999;
}

:local .columnsWrapper {
    display: flex;
    margin: 10px 25px;
    justify-content: center;
}

:local .column  {
    padding: 0 45px;
}

:local .borderLeft {
    border-left: 1px solid #888;
}

:local .columnHeader {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

:local .subcolumnsWrapper {
    display: flex;
}

:local .linksWrapper {
    padding: 0;
    list-style: none;
    li {
        padding-bottom: 8px;
        font-weight: 300;
        font-size: 14px;
    }
}

:local .multiColumn {
    padding-left: 20px;
}

:local .bottomText {
    font-size: 16px;
    text-align: center;
    color: #333;
    a {
        cursor: pointer;
    }
}
