@import '../../scss/shared.scss';


:local {

    .className {
        display: inline-block;
        vertical-align: middle;
        position: relative;

        :global .srp-wrapper & {
            position: absolute;
            height: 56px;
            left: 50%;
            transform: translate(-50%, 0);
            top: 272px;
            z-index: 9;
            
            @include nx-small-only {
                position: static;
                width: 100%;
            }

        }
    }
    .responsive{
        width: 100% !important;
        height: 36px !important;
    }

    .input {
        font-size: 15px;
        font-weight: 300;
        height: 30px;
        width: 280px;
        padding: 4px 4px 4px 30px;
        color: #333;
        border: 1px solid #ccc;
        border-radius: 2px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
        display: block;
        outline: none;

        :global .srp-wrapper & {
            height: 56px;
            width: 50vw;
            max-width: 680px;
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15), 0px -13px 34px rgba(0, 0, 0, 0.2);
            border-radius: 3px;
            border: none;

            @include nx-small-only {
                width: 100%;
            }
        }
    }

    .icon {
        position: absolute;
        left: 7px;
        top: 6px;
        width: 19px;
    }

    .resultsWrapper {
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        background-color: #fff;
        z-index: 10;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    .hidden {
        display: none;
    }

    .hasCarinaIcon {
         .input {
             padding-left: 56px;
             font-size: 18px;
             color: #24272B;
         }

        .carinaIcon {
            position: absolute;
            top: 16px;
            left: 16px;
        }
    }
    .showSearchIcon{
        position: absolute;
        margin: 10px;
        cursor: pointer;
    } 
}
