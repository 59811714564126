

:local .className {
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    background: #D1D3D9;

    :global(.SpinnerOverlay) {
        width: 100%;
        height: 100%;
        #{&}--content {
            width: 100%;
            height: 100%;
        }
    }
}

:local .imageContainer img,
:local .videoContainer {
    position: absolute;
    top: 0;
    margin: 0 auto;
    height: 100%;
    max-width: none;
    margin-left: 50%;
    transform: translateX(-50%);
}

:local .spinnerContainer {
    width: 100%;
    height: 100%;
    background: #D1D3D9;

    img {
        height: 100%;
        margin: auto;
    }
}

:local .className > img[hidden] {
    display: none;
}

:local .videoContainer {
    z-index: 1;
    width: 100%;
    height: 100%;
    :global(.SpinnerOverlay) {
        width: 100%;
        height: 100%;
        #{&}--content {
            width: 100%;
            height: 100%;
        }
    }
}

:local .hasVideo {
    cursor: pointer;
    img {
        position: absolute;
        top: 0;
        left: 0;
    }
}

:local .playerWrapper {
    height: 100%;
}

:local .playButton {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    display: inline-block;
    z-index: 2;
    padding: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;

    width: 70px;
    height: 70px;
    border: solid 4px #fff;
    border-radius: 100%;

    &:after {
        content: ' ';
        width: 30px;
        height: 30px;
        display: block;
        margin: 3px 10px 5px 15px;

        width: 0;
        height: 0;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;

        border-left: 18px solid #fff;
    }
}

