@import '../../scss/shared.scss';


:local .subHeaderContainer {
    display: block;
    width: 100%;
    height: 40px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0.03%, rgba(0, 0, 0, 0) 9.55%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF;
}

:local .breadcrumbs {
    padding: 8px 15px 8px 55px;
    float: left;
    max-width: 65%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #686868;

    @media(max-width: $screen-md) {
        max-width: 100%;
        padding-left: 0;
    }
}

:local .button {
    &:hover {
        color: #fff ;
    }
}

:local .breadcrumbArrow {
    float: left;
    padding: 11px 13px;
    display: none;
    color: #881155;
    @media(max-width: $screen-md) {
        display: inline-block;
    }
}

:local .breadcrumbLink {
    color: #686868;
    font-size: 15px;
    &:hover {
        color: #686868; 
    }
    &[href]:hover {
        color: #222;
    }
    @media(max-width: $screen-md) {
        display: none;
        &:nth-last-child(2) {
            display: inline-block;
            &:after {
                display: none;
            }
        }
    }
    &:after {
        content: '/';
        display: inline-block;
        padding: 0 10px;
        color: #aaa;
    }
    
    &:last-child {
        &:after {
            display: none;
        }
    }
}

:local .buttonContainer {
    display: inline-block;
}

:local .rightNav {
    float: right;
}

:local .button {
    display: inline-block;
    border-left: solid 1px #fff;
    border-right: solid 1px #fff;
    line-height: 36px;
    padding: 2px 10px;

    color: #fff;
    &:last-child {
        border: none;
    }
}

:local .estimate {
    color: #881155;
    font-weight: 600;
}