.SpinnerOverlay {
    &--content {
        position: relative;
        height: 100%;
    }
    &--spinner.Spinner {
        margin: 0;
        width: 100%;
        height: 100%;
    }
    &--spinner-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);

        &__no-opacity {
            background-color: transparent;
        }

        .LoadingSpinner--container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}