:local {
    #container {
        display:flex;
        flex-direction:column;
        align-items:center;
        width:375px;
        margin:auto;
        margin-bottom:28px;
        
    }
    #heading {
        font-size: 24px;
        margin:8px;
        font-family: 'Proxima Nova', sans-serif;
    }
    
    #subheading {
        font-size: 16px;
        margin:8px 8px 20px 8px;
        color:#69717A;
        text-align: center;
        font-family: 'Proxima Nova', sans-serif;
    }
}
