:local {
  .container {
    width: 360px;

    @media (max-width: 749px) {
      width: 100%;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    padding-top: 16px;
    margin-top: 15px;
    flex-direction: row;
    justify-content: center;
    border-top: 1px solid #d9dcde;
  }

  .footerButton {
    margin: 4px;
  }

  .body {
    max-width: 100%;
    max-height: 100%;
    flex: 0 0 auto;

    button {
      display: none !important;
    }
    img {
      width: 100%;
    }
  }

  .bodyContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px;
    padding: 21px 9px;
    h1 {
      font-size: 21px;
      line-height: 26px;
      text-align: center;
      color: #1a2026;
      margin-bottom: 11px;
    }
    span {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #69717a;
    }
  }

  .bodyMedia {
    display: block;
    width: 100%;
    height: 260px;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: scale-down;
    }
    video {
      height: 100%;
      width: 100%;
      object-fit: scale-down;
    }
  }
}
