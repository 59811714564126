:local {
    .container {
        display: flex;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        height: 80px;
        width: 100%;
        border: 1px solid #D9DCDE;
        margin-bottom: 5px;
    }
    .imageContainer {
        flex-shrink: 0;
        width: 80px;
        height: 80px;
        background: #d1d3d8 url("../../assets/images/rings.svg") no-repeat center;
        background-size: 75%;
        img {
            height: 80px;
            object-fit: cover;
            width: 100%;
        }
    }
    .descriptorContainer {
        flex-grow: 1;
        min-width: 0; // Trick to get text overflow + nowrap to work in flexbox
        padding: 11px 16px;
    }
    .title {
        font-size: 18px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .subtitle {
        font-size: 12px;
        margin-bottom: 2px;
        color: #69717A;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .closeContainer {
        flex-shrink: 0;
        cursor: pointer;
        padding: 10px 10px 0 0;
    }
}