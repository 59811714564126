@import '../../scss/shared.scss';


:local .clearfix {
    @include clearfix;
}

:local .className {
    position: relative;
    z-index: $z-index-modals;
    background-color: #fff;
    width: 400px;
    padding: 30px;
    margin: 0 auto;
    border-radius: 4px;

    @media (max-width: 768px) {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        overflow-y: auto;
    }
}

:local .closeBtn {
    font-size: 24px;
    color: #24272b;
    cursor: pointer;

    display: inline-block;
    width: 24px;
    height: 24px;

    position: absolute;
    left: auto;
    top: 16px;
    right: 16px;
}

:local .headerContainer {
    margin-bottom: 30px;
}
:local .headline {
    font-size: 23px;
    text-align: center;
    margin: 0;
}
:local .subheadline {
    font-size: 16px;
    margin: 0;
    text-align: center;
    color: #999;
}
:local .orWrapper {
    position: relative;
    text-align: center;
    margin: 15px 0;
    &:before {
        content: ' ';
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: #e9e9e9;
        top: 7px;
        left: 0;
    }
}

:local .or {
    position: relative;
    color: #999;
    padding: 0 15px;
    background-color: #fff;
}

:local .inputWrapper {
    position: relative;
}

:local .inputWrapperError {
    padding-bottom: 20px;
}

:local .input {
    text-align: left;
    height: 48px;
    margin-bottom: 12px;
    width: 100%;
    border-radius: 2px;
    border: solid 1px #e2e2e2;
    font-size: 17px;
    line-height: 26px;
    padding: 4px 6px;
    background-color: #fff;
    display: block;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border linear 0.2s, box-shadow linear 0.2s;
}

:local .inputError {
    border: 1px solid $offred-50;
    margin-bottom: 0;
}

:local .fieldError {
    color: $offred-50;
    position: absolute;
    bottom: 0;
    left: 0;
}

:local .nameWrapper {
    @include clearfix;
    .inputWrapper {
        width: 50%;
        float: left;

        &:first-child {
            padding-right: 5px;
        }
        &:last-child {
            padding-left: 5px;
            .fieldError {
                left: 12px;
            }
        }
    }
}

:local .signinButton {
    width: 100%;
    height: 50px;
    font-size: 17px;
}

:local .footer {
    margin-top: 5px;
    color: #999;
}
:local .footerText {
    text-align: center;
    font-size: 12px;
    padding: 0 70px;
    color: #999;
    margin: 15px 0;
}
:local .link {
    color: #535353;
}
:local .memberText {
    width: 50%;
    display: inline-block;
    text-align: right;
}
:local .accessText {
    width: 50%;
    display: inline-block;
    text-align: left;
}
:local .recaptchaError{
    margin-top: 10px;
    color: red;
    text-align: left;
}
:local .recaptchaResize{
    margin-bottom: 20px;
    transform:scale(1.125); transform-origin:0 0;
}