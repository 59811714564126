@import '../../scss/shared.scss';

:local .className {
    display: none;

    position: fixed;

    z-index: $z-index-modals + 1;  /* TODO: Make this configurable by application */


    background-color: rgba(0, 0, 0, 0.75);
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    overflow-x: hidden;
    overflow-y: auto;

}

:local .visible {
    display: flex;
    @media (max-width: 768px) {
        width: 100%;
    }
}

:local .inner {
    display: flex;

    @media (max-width: 768px) {
        width: 100%;
        position: absolute;
        top: 0
    }
}